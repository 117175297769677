var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper, Typography } from '@mui/material';
export default function SearchNotFound(_a) {
    var query = _a.query, sx = _a.sx, other = __rest(_a, ["query", "sx"]);
    return query ? (_jsxs(Paper, __assign({ sx: __assign({ textAlign: 'center' }, sx) }, other, { children: [_jsx(Typography, __assign({ variant: "h6", paragraph: true }, { children: "Not found" })), _jsxs(Typography, __assign({ variant: "body2" }, { children: ["No results found for \u00A0", _jsxs("strong", { children: ["\"", query, "\""] }), ".", _jsx("br", {}), " Try checking for typos or using complete words."] }))] }))) : (_jsx(Typography, __assign({ variant: "body2", sx: sx }, { children: "Please enter keywords" })));
}
